import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230117-Rate-Sheet.pdf";
const date = "17.01.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Tuesday 17 January 2023 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      <strong>UK Residential Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% LTV has reduced</li>
      <li>5 Year Fixed Fee Saver at 80% and 85% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% LTV has reduced</li>
      <li>5 Year Fixed Fee Saver at 80% and 85% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential First Time Buyer Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Home Mover Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>• 2 Year Tracker Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
      <li>2 Year Tracker Standard at 60% LTV has reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
      <li>2 Year Tracker Standard at 60% LTV has reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Purchase Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
    </ul>
    <p>
      <strong>UK Buy to Let Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60% LTV has reduced</li>
    </ul>
    <p>
      <strong>International Residential Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70% and 75% LTV have reduced</li>
    </ul>
    <p>
      We have introduced cashback to the UK Residential First Time Buyer and Home Mover buyer types within our 2 Year
      Fixed Fee Saver range at 80%, 85% and 90% LTV.
    </p>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 17 January to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
